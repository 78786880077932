
































import { debounceProcess } from "@/helpers/debounce";
import { useFindAllProductMerk } from "@/hooks";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { CreateMerkDTO } from "@/models/interface/product.interface";
import { productService } from "@/services/product.service";
import { Component, Prop, Vue } from "vue-property-decorator";

// TODO: rename to <SelectProductBrand />
@Component({
  components: {
    VNodes: {
      functional: true,
      render: (_h, ctx) => ctx.props.vnodes,
    },
  },
})
export default class MasterProductMerkSelect extends Vue {
  @Prop({ required: true, default: "", type: String })
  value!: string;

  @Prop({ required: false, default: false, type: Boolean })
  disabled!: boolean;

  merkOption = {
    data: [] as string[],
    search: "",
  };
  iconType: "plus" | "loading" = "plus";

  loading = false;

  created(): void {
    this.searchMerk = debounceProcess(this.searchMerk);

    this.getMerkList();
  }

  async getMerkList(): Promise<void> {
    try {
      this.loading = true;
      const param = new RequestQueryParams();
      if (this.merkOption.search) {
        param.search = `name~${this.merkOption.search}*`;
      }
      const res = await useFindAllProductMerk(param);
      this.merkOption.data = res.data;
    } finally {
      this.loading = false;
    }
  }

  searchMerk(value: string): void {
    this.merkOption.search = value;
    this.getMerkList();
  }

  handleChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  addNew(): void {
    if (this.iconType === "loading") return;
    const payload: CreateMerkDTO = {
      name: this.merkOption.search,
    };
    this.iconType = "loading";
    productService
      .addMerk(payload)
      .then(() => {
        this.merkOption.search = payload.name;
        this.getMerkList();
      })
      .finally(() => (this.iconType = "plus"));
  }
}
